









































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import Note from '@/models/Note';
import moment from 'moment';
import User from '@/models/User';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import {namespace} from 'vuex-class';
import {authStoreGetter} from '@/stores/auth.store';
import {Permission} from '@/misc/Enums/permission.enum';
import { jobOccurrenceStoreActions } from '@/stores/jobOccurrence.store';
import Customer from '@/models/Customer';

const AuthStore = namespace('auth');
const JobOccurrenceStore = namespace('jobOccurrence');

@Component({
  components: {RJTextField},
})
export default class TimeTrackingNotesComponent extends Vue {

  @Prop({default: []})
  public workSession!: WorkSession;
  @AuthStore.Getter(authStoreGetter.USER)
  private _user!: User;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSION_NOTES_ACTION)
  public loadWorkSessionNote!: (workSessionId: string) => Promise<Note[]>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.CREATE_WORK_SESSION_NOTE_ACTION)
  public createWorkSessionNote!: (note: Note) => Promise<Note>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.EDIT_WORK_SESSION_NOTE_ACTION)
  public editWorkSessionNote!: (note: Note) => Promise<Note>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.DELETE_WORK_SESSION_NOTE_ACTION)
  public deleteWorkSessionNote!: (noteId: string) => void;


  public get user() {
    return this._user;
  }

  public showDeleteDialog: boolean = false;

  public notes: Note[] = [];

  public newComment: string = '';

  public editMode: boolean = false;

  public selectedNote?: Note;
  public noteToDelete?: Note;


  public mounted() {
    this.reloadNotes();
  }

  @Watch('workSession')
  public onWorkSessionChange() {
    this.reloadNotes();
  }


  public async onSendNoteClick() {
    if (this.newComment) {
      if (this.editMode) {
        this.selectedNote!.text = this.newComment;
        await this.editWorkSessionNote(this.selectedNote!);
        delete this.selectedNote;
        this.editMode = false;
      } else {
        const note = new Note();
        note.text = this.newComment;
        note.workSessionId = this.workSession.id;
        await this.createWorkSessionNote(note);
      }
      this.newComment = '';
      await this.reloadNotes();
    }
  }

  public async onEditNoteClick(note: Note) {
    this.selectedNote = note;
    this.newComment = note.text ?? '';
    this.editMode = true;
  }

  public onCancelEdit() {
    delete this.selectedNote;
    this.newComment = '';
    this.editMode = false;
  }

  public async onDeleteNoteClick(note: Note) {
    this.noteToDelete = note;
    this.showDeleteDialog = true;
  }

  public async onDeleteNote() {
    await this.deleteWorkSessionNote(this.noteToDelete!.id!);
    delete this.noteToDelete;
    this.showDeleteDialog = false;
    await this.reloadNotes();
  }

  public textIsOwn(note: Note) {
    return note.writerId === this.user.id;
  }

  public canEditWorkSession() {
    return this.$userRoleHandler.hasPermission(Permission.WORKSESSION_UPDATE_OWN);
  }

  public async reloadNotes() {
    this.notes = await this.loadWorkSessionNote(this.workSession.id);
    this.$emit('reloadNotes', this.notes.length);
    this.$nextTick(() => {
      const el = this.$refs.notes! as HTMLElement;
      if (el) {
        el.scrollBy({
          top: el.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    });

  }

}
