








































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import Topic from '@/models/Topic';
import {Conditions} from '@/misc/Enums/Constants';
import {getTopicImage, Topics} from '@/misc/Enums/Topics';

@Component({
  components: {},
})
export default class TimeTrackingTopicsComponent extends Vue {

  @Prop({default: []})
  public workSession!: WorkSession;

  @Prop({default: []})
  public photos!: any[];

  public showSubTopicDialog: boolean = false;
  public selectedTopic?: Topic = new Topic();

  public readonly IMAGE_SIZE = window.outerHeight / 5;

  public getImagesOfSubTopic(subTopicId: string): any[] {
    return this.photos.filter((photo) => photo.subTopicId === subTopicId);
  }

  public onTopicClicked(item: Topic) {
    this.showSubTopicDialog = true;
    this.selectedTopic = item;
  }

  public getTopicIcon(topic: Topics) {
    return getTopicImage(topic);
  }

  public getImage(image: any) {
    const buffer = Buffer.from(image.data);
    return 'data:image/png;base64,' + buffer.toString('base64');
  }

  public getConditionColor(condition: Conditions): string {
    switch (condition) {
      case Conditions.GOOD :
      case Conditions.RESOLVED:
        return 'condition-good';
      case Conditions.NO_INFO:
        return 'condition-no-info';
      case Conditions.PARTLY_RESOLVED:
        return 'condition-partly';
      default:
        return 'condition-bad';
    }
  }
}
