
import {TimeDuration} from '@/helper/TimeDuration';
import moment from 'moment';
import i18n from '@/i18n';
import TimeSchedule from '@/models/TimeSchedule';
import {WeekDays} from '@/misc/Enums/WeekDay';
import VueI18n from 'vue-i18n';



/**
 * Returns the time for the ending day of a night shift
 * @param jobTime
 * @param jobDate
 * @param dayOne
 * @param analyticsRecurs
 * @param convertedTime
 */
function getTimeForTwoDays(jobTime: TimeDuration, jobDate: moment.Moment, dayOne: moment.Moment,
                           analyticsRecurs: string[], convertedTime: { day: number, hour: number, minute: number, seconds: number}): string {
  let timeString = '';

  // Get second day of analyticsRecurs array
  const dayTwo = moment(analyticsRecurs![1]);

  // Check if Date is the same for day, month and year
  if (jobDate.isSame(dayTwo, 'day') && jobDate.isSame(dayTwo, 'month') && jobDate.isSame(dayTwo, 'year')) {
    let leftTime = getTimeAfterEndOfDay(dayTwo.hour(), convertedTime.hour);
    let endTimeMinutes = jobTime.minute + convertedTime.minute;
    if (endTimeMinutes >= 60) {
      leftTime += 1;
      endTimeMinutes -= 60;
    }
    timeString += `00:00 - ${ getFormattedTime(leftTime, endTimeMinutes) } ${ i18n.t('GENERAL.CLOCK') }<br />`;
  }

  timeString += getNormalJobTime(jobTime, convertedTime);

  return timeString;
}

/**
 * Returns the time for one day
 * @param jobTime
 * @param convertedTime
 */
function getTimeForOneDay(jobTime: TimeDuration, convertedTime: { day: number, hour: number, minute: number, seconds: number}): string {
  let timeString = '';

  // is a night shift => calculates the day format, where the beginning is always 00:00 (=> e.g. 00:00 - 05:00)
  let endTime = jobTime.hour + convertedTime.hour;
  let endTimeMinutes = jobTime.minute + convertedTime.minute;
  if (endTimeMinutes >= 60) {
    endTime += 1;
    endTimeMinutes -= 60;
  }

  // checks if the endTime is over 24, if so lowers the endTime by 24 to get the right format (=> e.g. from 27:00 to 03:00)
  if (endTime >= 24) {
    endTime = endTime % 24;
  }

  // sets the timeString to the right format
  timeString = `00:00 - ${getFormattedTime(endTime, endTimeMinutes)} ${i18n.t('GENERAL.CLOCK')} <br />`;

  return timeString;
}

/**
 * Returns the "normal" job time
 * @param jobTime
 * @param convertedTime
 */
function getNormalJobTime(jobTime: TimeDuration, convertedTime: { day: number, hour: number, minute: number, seconds: number}): string {
  let hours = jobTime.hour + convertedTime.hour;

  // Calculate minutes and check if its above 60
  let minutes = jobTime.minute + convertedTime.minute;
  if (minutes >= 60) {
    minutes -= 60;
    hours += 1;
  }

  if (hours >= 24) {
    hours = 23;
    minutes = 59;
  }

  return `${getFormattedTime(jobTime.hour, jobTime.minute)} - ${getFormattedTime(hours, minutes)} ${ i18n.t('GENERAL.CLOCK') } <br />`;
}

/**
 * Returns the time for the ending day
 * @param hours
 * @param durationHours
 */
function getTimeAfterEndOfDay(hours: number, durationHours: number) {
  const time = hours + durationHours;
  return time % 24;
}

/**
 * Convert Milliseconds in Time Object with
 * day, hour, minutes and seconds
 * @param milliseconds
 */
export function convertMs(milliseconds: number): { day: number, hour: number, minute: number, seconds: number} {
  // Declare variables
  let day;
  let hour;
  let minute;
  let seconds;

  // Calculate Times
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;

  // Return object with all times
  return {
    day,
    hour,
    minute,
    seconds,
  };
}

/**
 * Formats given hour and minute in HH:mm format
 * @param hour
 * @param minute
 */
export function getFormattedTime(hour: number, minute: number) {
  const hours = hour.toString().padStart(2, '0');
  const minutes = minute.toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

/**
 * Returns a string representation of the byWeekArray found in the timeSchedule
 */
export function getJobFrequency(timeSchedule: TimeSchedule, lang: VueI18n): string {

  // If every day return with appropriate message
  if (timeSchedule.weekdays.length === 0 || timeSchedule.weekdays.length >= 7) {
    return lang.t(`JOB_OVERVIEW.EVERY_DAY`).toString();
  }

  // preserves the order of days
  const days = [];
  for (const day of WeekDays) {
    if (timeSchedule.weekdays.includes(day.index)) {
      days.push(lang.t(`GENERAL.DAYS_SHORT.${day.name.toUpperCase()}`).toString());
    }
  }

  return days.join(', ');
}
