



















































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {jobOccurrenceStoreActions} from '@/stores/jobOccurrence.store.ts';
import WorkSession from '@/models/WorkSession';
import moment from 'moment';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import TimeTrackingSidebarContentComponent from '@/components/time-tracking/TimeTrackingSidebarContent.component.vue';
import {authStoreGetter} from '@/stores/auth.store';
import {tenantStoreGetter} from '@/stores/tenant.store';
import Tenant from '@/models/Tenant';

const JobOccurrenceStore = namespace('jobOccurrence');
const TenantStore = namespace('tenant');
@Component({
  components: {
    SideCardComponent,
    TimeTrackingSidebarContentComponent,
    TimeTrackingHeader: () => import(
        '@/components/time-tracking/TimeTrackingHeader.component.vue'),
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
    TimeTrackingFilter: () => import(
        '@/components/time-tracking/TimeTrackingFilter.component.vue'),
    TimeTrackingLegend: () => import(
        '@/components/time-tracking/TimeTrackingLegend.component.vue'),
  },
})

export default class TimeTrackingOverviewView extends Vue {
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.DOWNLOAD_WORK_SESSIONS_PDF)
  private getPDF!: (id: string) => any;
  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private _tenant!: Tenant;

  public get tenant() {
    return this._tenant;
  }

  private filteredWorkSession: WorkSession[] = [];
  private mode: 'day' | 'week' | 'custom' = 'week';
  private pickerDate: string = moment().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString());
  private showSideCard: boolean = false;
  private isLoading: boolean = false;
  private selectedWorkSession: WorkSession = new WorkSession();

  private get tableHeaders(): any {
    return [
      {text: this.$t('GENERAL.EMPLOYEE').toString(), value: 'user', width: '30%'},
      {text: this.$t('GENERAL.LOCATION').toString(), value: 'location', width: '25%'},
      {text: this.$t('TIMETRACKING.HEADER.DATE').toString(), value: 'date', width: '10%'},
      {text: this.$t('JOB_COMPONENT.TERM').toString(), value: 'time', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.CONDITION').toString(), value: 'condition', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.EXTRA').toString(), value: 'extra', width: '10%'},
      {text: this.$t('TIMETRACKING.HEADER.EXPORT').toString(), value: 'export', width: '5%'},
    ];
  }

  private async downloadPDF(workSession: WorkSession) {
    const pdf = await this.getPDF(workSession.id);
    const a = document.createElement('a');
    a.href = await pdf.text();
    a.download = `${workSession.user.firstName[0]}${workSession.user.lastName[0]}_workSession_${workSession.id}.pdf`;
    a.click();
  }

  private toggleSideCard(workSession: WorkSession) {
    this.showSideCard = true;
    this.selectedWorkSession = workSession;
  }

  private isInProgress(item: any) {
    if (!item.endTime) {
      return 'workInProgress ';
    } else if (item.renderData.conditions.positives) {
      return 'goodCondition ';
    } else if (item.renderData.conditions.negatives) {
      return 'badCondition ';
    }
    return 'goodCondition ';
  }

  private loadingStart() {
    this.isLoading = true;
    this.filteredWorkSession = [];
  }

  private workSessionSorter(item: any) {
    if (Array.isArray(item) && item.length > 1) {
      const isAsc = moment(item[0].startDate).isBefore(item[1].startDate);

      item.sort((a: any, b: any) => {
        if (!isAsc) {
          [a, b] = [b, a];
        }
        return moment(a.startDate).diff(moment(b.startDate));
      });
    }
    return item;
  }
}
