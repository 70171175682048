










































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';

@Component
export default class RJTextField extends Vue {

  @VModel({default: ''})
  public textInput!: any;

  @Prop({default: undefined})
  public appendIcon!: string;

  @Prop({default: undefined})
  public vMask!: string;

  @Prop({default: false})
  public disabled!: boolean;

  @Prop({default: ''})
  public styleClass!: string;

  @Prop({default: undefined})
  public value!: any;

  @Prop({default: undefined})
  public label!: string;

  @Prop({default: 'text'})
  public type!: string;

  @Prop({default: false})
  public hideSpinButtons!: boolean;

  @Prop({default: undefined})
  public min!: number;

  @Prop({default: undefined})
  public max!: number;

  @Prop({default: undefined})
  public height!: number;

  @Prop({default: null})
  public errorMessages!: string | null;

  @Prop({default: false})
  public readonly!: boolean;

  @Prop({default: () => []})
  public rules!: any[];

  @Prop({default: undefined})
  public vOn!: any;

  private onInput(value: any) {
    // This emit with value is needed... Why?!?!?
    this.$emit('input', value);
  }

  private onFocus(value: any) {
    this.$emit('focus', value);
  }
}
