var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-grow-1 ma-10"},[_c('div',{staticClass:"d-flex flex-grow-1 flex-column"},[_c('time-tracking-header',{attrs:{"mode":_vm.mode},model:{value:(_vm.pickerDate),callback:function ($$v) {_vm.pickerDate=$$v},expression:"pickerDate"}}),_c('table-component',{staticClass:"custom-border",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredWorkSession,"height":'calc(100vh - 64px - 105px - 35px)',"onRowClick":_vm.toggleSideCard,"get-row-class":_vm.isInProgress,"is-loading":_vm.isLoading,"has-legend":true},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.fullName)+" ")]}},{key:"location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.location.name)+" ")]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.startDate))+" ")]}},{key:"time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toZoneTime")(item.startDate))+" - "+_vm._s(item.endTime ? item.endTime.hour + ':' + item.endTime.minute.toString().padStart(2, '0') : ' ')+" ")]}},{key:"condition",fn:function(ref){
var item = ref.item;
return [(item.renderData.conditions.positives)?[_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.renderData.conditions.positives)+" "),_c('v-icon',{staticStyle:{"color":"green"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('CONDITIONS.-1')))])])]:_vm._e(),(item.renderData.conditions.negatives)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.renderData.conditions.negatives)+" "),_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('CONDITIONS.-3')))])])]:_vm._e()]}},{key:"extra",fn:function(ref){
var item = ref.item;
return [(item.notes? item.notes.length : 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_vm._v(" "+_vm._s(item.notes ? item.notes.length : 0)+" "),_c('v-icon',_vm._g({},on),[_vm._v("mdi-message")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('TIMETRACKING.HEADER.NOTES')))])]):_vm._e(),(item.renderData.images)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_vm._v(" "+_vm._s(item.renderData.images)+" "),_c('v-icon',_vm._g({},on),[_vm._v("mdi-camera")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.IMAGES')))])]):_vm._e(),(item.renderData.comments)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_vm._v(" "+_vm._s(item.renderData.comments)+" "),_c('v-icon',_vm._g({},on),[_vm._v("mdi-comment-text")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.COMMENTS')))])]):_vm._e()]}},{key:"export",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","text":"","disabled":_vm.tenant.paymentDelayed()},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPDF(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1)],1)]}}],null,true)},[(_vm.tenant.paymentDelayed())?_c('span',[_vm._v(_vm._s(_vm.$t('SETTINGS.TENANT_PAYMENT.DELAYED_PAYMENT_TOOLTIP')))]):_c('span',[_vm._v(_vm._s(_vm.$t('GENERAL.DOWNLOAD')))])])]}},{key:"legend",fn:function(){return [_c('time-tracking-legend')]},proxy:true}])})],1),_c('time-tracking-filter',{on:{"mode":function (newMode) { return this$1.mode = newMode; },"start":_vm.loadingStart,"end":function () { return this$1.isLoading = false; },"Filtered":function (filtered) { return this$1.filteredWorkSession = filtered; }},model:{value:(_vm.pickerDate),callback:function ($$v) {_vm.pickerDate=$$v},expression:"pickerDate"}}),_c('side-card-component',{attrs:{"card-title":_vm.$t('JOB_OCCURRENCE_OVERVIEW.SIDEBAR.JOB_DETAILS').toString(),"showSideCard":_vm.showSideCard},on:{"close-side-card":function($event){_vm.showSideCard = !_vm.showSideCard}}},[_c('time-tracking-sidebar-content-component',{attrs:{"work-session":_vm.selectedWorkSession}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }