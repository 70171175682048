













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Signature from '../../models/Signature';

@Component({})
export default class SignatureComponent extends Vue {

  @Prop({})
  public signature!: Signature;
  private canvas!: HTMLCanvasElement;
  private SIGNATURE_ANIMATION_DELAY: number = 10;
  private SIGNATURE_LINE_WIDTH: number = 2;
  private SIGNATURE_SCALE_FACTOR: number = 0.8;

  /**
   * flags that monitor the drawing process when the Signature is changed mid-Drawing
   */
  private updateSignature = false;
  private isDrawing = false;

  public mounted() {
    this.canvas = this.$refs.canvas as HTMLCanvasElement;
    this.drawSignature();
  }

  @Watch('signature')
  private onSignatureChange() {
    this.updateSignature = true;
    if (!this.isDrawing) {
      this.drawSignature();
    }
  }


  /**
   * Components main function. Draws the given signature.
   */
  private async drawSignature() {
    this.isDrawing = true;

    const ctx = this.canvas.getContext('2d')!;
    const pathData = this.signature.pathData;

    // Resizes the signature resolution so it fits to the size
    const parents = document.getElementsByClassName('signature-container');
    this.SIGNATURE_SCALE_FACTOR = parents[0].clientWidth / pathData.width - 0.1;

    // adjust canvas size
    this.canvas.width = pathData.width * this.SIGNATURE_SCALE_FACTOR;
    this.canvas.height = pathData.height * this.SIGNATURE_SCALE_FACTOR;

    ctx.scale(this.SIGNATURE_SCALE_FACTOR, this.SIGNATURE_SCALE_FACTOR);
    ctx.lineWidth = this.SIGNATURE_LINE_WIDTH;

    // drawing loop
    outerLoop:
    for (const segment of pathData.data) {
      ctx.beginPath();
      for (const part of segment) {
        // drawing process is interrupted by a new Signature
        if (this.updateSignature) {
          break outerLoop;
        }
        // subtract the offset, to place the signature start point at 0,0
        ctx!.lineTo(part.x - pathData.xOffset, part.y - pathData.yOffset);
        await new Promise<void>((resolve) => setTimeout(() => resolve(), this.SIGNATURE_ANIMATION_DELAY));
        ctx.stroke();
      }
    }
    // draw new Signature if interrupted
    if (this.updateSignature) {
      this.updateSignature = false;
      await this.drawSignature();
    }
    this.isDrawing = false;
  }
}
