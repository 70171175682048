














































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Job from '@/models/Job';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import WorkSession from '@/models/WorkSession';
import i18n from '@/i18n';
import {namespace} from 'vuex-class';
import SignatureComponent from '@/components/shared/Signature.component.vue';
import User from '@/models/User';
import Location from '@/models/Location';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import TimeSchedule from '@/models/TimeSchedule';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import {TabItem} from '@/interfaces/TabItem';
import {getJobFrequency} from '@/helper/JobTimeHelper';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import {locationStoreActions} from '@/stores/location.store';
import {jobStoreActions} from '@/stores/job.store';
import moment from 'moment';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import TimeTrackingNotesComponent from '@/components/time-tracking/TimeTrackingNotes.component.vue';
import TimeTrackingTopicsComponent from '@/components/time-tracking/TimeTrackingTopics.component.vue';
import Image from '@/models/Image';
import {jobOccurrenceStoreActions} from '@/stores/jobOccurrence.store';

const JobStore = namespace('job');
const CustomerStore = namespace('customer');
const LocationStore = namespace('location');
const JobOccurrenceStore = namespace('jobOccurrence');

/**
 * This component represents the content of the side card used to display job details in the jobs overview.
 */
@Component({
  components: {
    TimeTrackingTopicsComponent,
    RJTextField,
    RJTabs,
    SignatureComponent,
    UserInitialsComponent,
    TimeTrackingNotesComponent,
  },
})
export default class TimeTrackingSidebarContentComponent extends Vue {

  @JobStore.Action(jobStoreActions.LOAD_JOB_ACTION)
  public loadJobAction!: (jobId: string) => Promise<Job>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  public loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.LOAD_WORK_SESSION_IMAGES)
  public loadWorkSessionImages!: (workSessionId: string) => Promise<any>;

  @Prop({default: undefined})
  public workSession?: WorkSession;

  public loadedJob: Job = new Job();
  public loadedLocation: Location = new Location();

  public notesLength: number = 0;

  public photos?: any[] = [];

  // expander
  private tabsModel: number = 0;

  private userConfig: UserInitialsConfig = {showTooltip: false, showFullName: true, big: true};


  public get getTabItems(): TabItem[] {
    return [
      {
        key: 'employees',
        text: this.$t('GENERAL.EMPLOYEES').toString(),
        available: true,
      }, {
        key: 'topics',
        text: this.$t('LOCATION_MANAGE.TOPICS').toString(),
        available: true,
      }, {
        key: 'notes',
        text: this.$t('TIMETRACKING.HEADER.NOTES').toString(),
        available: true,
      },
    ];
  }

  public getJobFrequency = (timeSchedule: TimeSchedule) => getJobFrequency(timeSchedule, this.$i18n);


  get locale(): string {
    return i18n.locale;
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  /**
   * When the selected event changes, update all information not immediately contained in the event.
   * @private
   */
  @Watch('workSession')
  private async onEventChanged() {
    if (this.workSession) {
      this.loadJobAction(this.workSession.jobId).then((value) => {
        this.loadedJob = value;
      });
      this.loadLocationAction({locationId: this.workSession.locationId, shouldBeStored: false}).then((value) => {
        this.loadedLocation = value;
      });
      await this.loadWorkSessionImages(this.workSession.id).then((value) => {
        this.photos = value.records;
      });
      this.notesLength = this.workSession.notes?.length ?? 0;
    }
  }

  // TODO: Rename 'Dashboard' to 'Details'. Make constants for view names. Make one Method for routing here
  public onUserClick(user: User) {
    this.$router.push({
      name: 'userDetails', params: {
        tenantId: this.$route.params.tenantId,
        userId: user.id!,
      },
    });
  }

  private onLocationClick() {
    this.$router.push({
      name: 'locationDashboard',
      params: {
        tenantId: this.$route.params.tenantId,
        locationId: this.loadedLocation!.id!,
      },
    });
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()}:${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS.ABBRV.HOUR')}`;
  }

  public onReloadNotes(notesLength: number) {
    this.notesLength = notesLength;
  }
}
