













import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    DashboardComponent: () => import(
        /* webpackChunkName: "TenantDashboardComponent" */
        '@/components/tenant/TenantDashboard.component.vue'),
  },
})
export default class TenantDashboardView extends Vue {

}
